<template>
  <textarea
    class="input-textarea"
    :value="value"
    :name="name"
    rows="5"
    @input="$emit('input', $event.target.value)"
  />
</template>

<script>
export default {
  name: 'InputTextarea',
  props: {
    value: String,
    name: String,
  },
};
</script>

<style lang="scss" scoped>
.input-textarea {
  @include styled-input;
  background-color: $gray-900;
  color: $gray-500;
  resize: none;
}
</style>
