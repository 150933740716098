<template>
  <div class="container">
    <div class="contact">
      <header class="header">
        <h1>Kontakt</h1>
        <p>Máte pre nás feedback alebo otázky? Napíšte nám.</p>
      </header>
      <form
        action="https://formsubmit.co/alex.mravcak@gmail.com"
        method="POST"
      >
        <FormGroup
          label="Váš email (nepovinné)"
        >
          <InputText
            type="email"
            name="_replyto"
            v-model="fields.email"
          />
        </FormGroup>
        <FormGroup
          label="Váš podnet"
        >
          <InputTextarea
            name="message"
            v-model="fields.message"
          />
        </FormGroup>
        <BaseButton type="submit">
          Odoslať
        </BaseButton>
      </form>
    </div>
  </div>
</template>

<script>
import FormGroup from '@/components/FormGroup.vue';
import InputText from '@/components/InputText.vue';
import InputTextarea from '@/components/InputTextarea.vue';
import BaseButton from '@/components/BaseButton.vue';

export default {
  name: 'Contact',
  components: {
    FormGroup,
    InputText,
    InputTextarea,
    BaseButton,
  },
  data: () => ({
    fields: {
      email: '',
      message: '',
    },
  }),
};
</script>

<style lang="scss" scoped>
.contact {
  max-width: 600px;
  padding: 2rem;
  margin: 0 auto;
  border-radius: 10px;
  background-color: $gray-700;
  text-align: center;
  .header {
    margin-bottom: 2rem;
  }
  @media screen and (min-width: 768px) {
    padding: 2rem 4rem;
  }
}
</style>
